<template>
  <div class="app flex-row align-items-center">
    <a
      class="logout btn btn-light"
      href="/logout"
    >Выход</a>
    <div class="container">
      <b-row class="justify-content-center">
        <b-col
          v-if="this.$store.state.user.verificationStatus === 0"
          md="12"
        >
          <h1 class="mb-3">
            Верификация
          </h1>
          <p class="text-muted">
            Прикрепите документы необходимые для прохождения верефикации.
          </p>
          <b-row>
            <b-col
              v-for="document in documents"
              :key="document.sampleDocument.id"
              lg="3"
              sm="6"
            >
              <div class="file-upload">
                <p class="file-upload__title">
                  <label class="file-upload__label">{{ document.sampleDocument.documentType.name }}.</label>
                  <br>
                  <a
                    class="file-upload__link"
                    :href="document.sampleDocument.documentType.example.path"
                    download
                  >скачать пример</a>
                </p>
                <div class="file-upload__input-container">
                  <el-upload
                    :ref="document.sampleDocument.id"
                    class="file-upload__input"
                    :class="{'added' : document.document[0]}"
                    drag
                    action="/api/validation-documents"
                    accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                    :multiple="false"
                    :limit="1"
                    :auto-upload="false"
                    :file-list="document.document"
                    :on-remove="removeDoc"
                    :on-change="base64UploadDoc"
                  >
                    <i class="fa fa-upload" />
                    <div class="el-upload__text">
                      Перетащите или
                      <em>выберите файлы</em>
                    </div>
                    <div
                      slot="tip"
                      class="el-upload__tip"
                    >
                      Максимальный объем загружаемого файла, не должен превышать 15MB
                    </div>
                  </el-upload>
                  <button
                    v-if="document.document[0]"
                    class="btn btn-primary btn-sm mt-2"
                    type="button"
                    size="sm"
                    @click="downloadDocument(document.document[0].url, document.document[0].name)"
                  >
                    скачать&nbsp;
                    <i class="fa fa-download" />
                  </button>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-button
            class="mt-5"
            type="button"
            variant="primary"
            :disabled="loading"
            :class="{'loading' : loading}"
            @click="onConfirm"
          >
            Отправить
          </b-button>
        </b-col>
        <b-col
          v-else-if="this.$store.state.user.verificationStatus === 1"
          md="12"
        >
          <h1 class="mb-3">
            Верификация
          </h1>
          <p class="text-muted mb-1">
            Поздравляем, Вы успешно верифицированны.
          </p>
          <p class="text-muted">
            {{ comment }}
          </p>
          <b-button
            to="/"
            class="mt-5"
            type="button"
            variant="primary"
          >
            Начать
          </b-button>
        </b-col>
        <b-col
          v-else-if="this.$store.state.user.verificationStatus === 2"
          md="12"
        >
          <h1 class="mb-3">
            Верификация
          </h1>
          <p
            class="text-muted"
          >
            В данный момент Ваша заявка находится на рассмотрении. После принятия решения, на Вашу почту будет отправлено сообщение с итогами. При успешном прохождении верификации Вам станет доступен функционал сайта.
          </p>
          <b-row>
            <b-col
              v-for="document in documents"
              :key="document.sampleDocument.id"
              lg="3"
              sm="6"
            >
              <div class="file-upload">
                <p class="file-upload__title">
                  <label class="file-upload__label">{{ document.sampleDocument.documentType.name }}.</label>
                  <br>
                </p>
                <button
                  v-if="document.document[0]"
                  class="btn btn-primary btn-sm mt-2"
                  type="button"
                  size="sm"
                  @click="downloadDocument(document.document[0].url, document.document[0].name)"
                >
                  скачать&nbsp;
                  <i class="fa fa-download" />
                </button>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-else-if="this.$store.state.user.verificationStatus === 3"
          md="12"
        >
          <h1 class="mb-3">
            Верификация
          </h1>
          <p class="text-muted mb-1">
            Вы получили отказ, со следующим сообщением:
          </p>
          <p class="text-muted">
            {{ comment }}
          </p>
        </b-col>
      </b-row>
    </div>

    <b-modal
      v-model="dangerModal.show"
      :title="dangerModal.title"
      class="modal-danger"
      cancel="false"
      ok-only
      ok-title="OK"
      @ok="dangerModal.show = false"
    >
      {{ dangerModal.desc }}
    </b-modal>

    <b-modal
      v-model="warningModal.show"
      :title="warningModal.title"
      class="modal-warning"
      cancel-title="Назад"
      ok-title="OK"
      @ok="onSubmit"
    >
      {{ warningModal.desc }}
    </b-modal>
  </div>
</template>

<script>
import notifications from '../components/mixins/notifications';
import {documentDownload, documentCreate, contractorsValidationDocuments, contractorToVerify, validationDocumentsList, contractorRead} from '../services/api';
import {saveAs} from 'file-saver';

// Import Vue FilePond
import vueFilePond from 'vue-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType);

export default {
  name: 'Verification',
  components: {
    FilePond,
  },
  mixins: [notifications],
  data() {
    return {
      loading: false,
      documents: [],
      comment: '',
      dangerModal: {
        show: false,
        title: 'Ошибка',
        desc: '',
      },
      warningModal: {
        show: false,
        title: 'Предупреждение',
        desc: '',
      },
    };
  },
  mounted() {
    this.validationDocumentsList();
  },
  methods: {
    async validationDocumentsList() {
      this.loading = true;
      const response = await validationDocumentsList();
      if (response && response.status === 200) {
        this.documents = response.data.items.map((sampleDocument) => {
          return {
            sampleDocument,
            document: [],
          };
        });
        this.contractorRead();
      }
      this.loading = false;
    },
    async contractorRead() {
      this.loading = true;
      const response = await contractorRead(this.$store.state.user.ownerId);
      if (response && response.status === 200) {
        if (response.data.comment) {
          this.comment = response.data.comment;
        }
        if (response.data.contractor.contractorValidationDocuments) {
          response.data.contractor.contractorValidationDocuments.forEach(
            (document) => {
              this.documents.forEach((sampleDocument) => {
                if (
                  document.document.documentType.id ===
                  sampleDocument.sampleDocument.documentType.id
                ) {
                  sampleDocument.document = [
                    {
                      name: document.document.file.originalName,
                      url:
                        '/api/files/' +
                        document.document.file.guid +
                        '/download',
                    },
                  ];
                }
              });
            },
          );
        }
      }
      this.loading = false;
    },
    async downloadDocument(url, name) {
      this.loading = true;
      const response = await documentDownload(url);
      if (response && response.status === 200) {
        saveAs(response.data, name);
      }
      this.loading = false;
    },
    async contractorsValidationDocuments(documentId, formStr) {
      this.loading = true;
      const response = await contractorsValidationDocuments(formStr);
      if (response && response.status === 201) {
        this.$refs[documentId][0].$el.classList.add('added');
      }
      this.$refs[documentId][0].$el.classList.remove('loading');
      this.loading = false;
    },
    async documentCreate(documentId, upload) {
      this.loading = true;
      const response = await documentCreate(upload);
      if (response && response.status === 201) {
        const formStr = {
          contractor: {
            id: this.$store.state.user.ownerId,
          },
          document: {
            id: response.data.id,
          },
          validationDocument: {
            id: documentId,
          },
        };
        this.contractorsValidationDocuments(documentId, formStr);
      }
      this.loading = false;
    },
    base64UploadDoc(file) {
      if (file.size >= 15 * 1024 * 1024) {
        this.showError('Файл слишком большой. Максимальный объем загружаемого файла не должен превышать 15MB.');
      } else {
        const fileId = file.uid;
        let documentId = false;
        let documentName = '';
        Object.keys(this.$refs).map((ref) => {
          if (this.$refs[ref][0].uploadFiles.length > 0) {
            if (this.$refs[ref][0].uploadFiles[0].uid === fileId) {
              documentId = Number(ref);
              documentName = this.$refs[ref][0].uploadFiles[0].name;
            }
          }
        });
        if (documentId && documentName) {
          this.$refs[documentId][0].$el.classList.add('loading');
          let documentTypeId;
          this.documents.map((document) => {
            if (document.sampleDocument.id === documentId) {
              documentTypeId = document.sampleDocument.documentType.id;
            }
          });
          const reader = new FileReader();
          reader.readAsDataURL(file.raw);
          reader.onload = () => {
            const formStr = {
              base64file: reader.result,
              originalName: documentName,
              documentType: {
                id: documentTypeId,
              },
            };
            this.documentCreate(documentId, formStr);
          };
          reader.onerror = (error) => {
            console.log('Error: ', error);
          };
        }
      }
    },
    removeDoc() {
      console.log('removeDoc');
    },
    onConfirm() {
      let documentTypeId = 0;
      Object.keys(this.$refs).map((ref) => {
        if (this.$refs[ref][0].uploadFiles.length > 0) {
          documentTypeId++;
        }
      });
      if (1 <= documentTypeId) {
        this.warningModal = {
          show: true,
          title: 'Предупреждение',
          desc: 'После отправки документов на верификацию Вы уже не сможете их изменить. Если Вы уверены что хотите отправить документы на верификацию нажмите «ОК», если Вы ещё не прикрепили все необходимые документы нажмите кнопку «Назад».',
        };
      } else {
        this.dangerModal = {
          show: true,
          title: 'Ошибка',
          desc: 'Вы должны прикрепить хотя бы один документ',
        };
      }
    },
    async onSubmit() {
      this.loading = true;
      const response = await contractorToVerify(this.$store.state.user.ownerId, '');
      if (response && response.status === 200) {
        this.$router.go();
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
  .file-upload {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__title {
    }
    &__label {
    }
    &__link {
    }
    &__input {
      .el-upload {
        display: block;
        width: 100%;
      }
      /*TODO убрать для удаления*/
      .el-icon-close,
      .el-icon-close-tip {
        display: none !important;
      }
      .el-upload-dragger {
        width: 100%;

        .fa-upload {
          margin: 40px 0 16px;
          line-height: 50px;
          font-size: 50px;
          color: #c0c4cc;
        }
      }
      &.loading {
        .el-upload {
          .fa.fa-upload {
            &:before {
              display: inline-block;
              font: normal normal normal 14px/1 FontAwesome;
              font-size: inherit;
              text-rendering: auto;
              -webkit-animation: fa-spin 2s infinite linear;
              animation: fa-spin 2s infinite linear;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: "\F1CE";
            }
          }
        }
      }
      &.added {
        .el-upload {
          .el-upload__text {
            display: none;
          }
          .fa.fa-upload {
            margin-top: 64px;

            &:before {
              color: green;
              content: "\F058";
            }
          }
        }
      }
    }
  }

  .logout {
    position: fixed;
    top: 15px;
    right: 15px;
  }
</style>
